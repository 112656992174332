import React from 'react';

const IconArrowDown = () => {
  return (
    <svg width="13.152" height="13.152" viewBox="0 0 13.152 13.152">
        <path d="M0 8.05V0h8.05" transform="rotate(-135 5.829 4.772)" style={{fill:"none", stroke:"currentColor", strokeLinecap:"round", strokeWidth: "1.25px"}} />
    </svg>
  );
};

export default IconArrowDown;
