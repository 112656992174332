import React from 'react';
import { graphql, Script } from 'gatsby';
import { PageWrapper } from '../components/Layout/PageWrapper';
import DealerList from '../components/DealerList/DealerList';
import Container from '../components/Layout/SharedStyles/Container';
import { PageIntro } from '../components/Layout/PageIntro/PageIntro';
import FindARetailer from '../components/Dealer/FindARetailer';

const DealerPages = ({
  data: {
    datoCmsDealerPage: {
      title,
      locations,
      id,
      metaTags,
      useStoreLocator,
      pageLeadText,
      contactUs,
    },
    homeCrumb,
    contactPage,
  },
  pageContext,
}) => {
  const isBrowser = () => typeof window !== 'undefined' && window;

  const breadcrumbs = [
    { title: homeCrumb.title, id: homeCrumb.id },
    { title: title, id: id },
  ];

  return (
    <PageWrapper pageData={pageContext} metaTags={metaTags}>
      <PageIntro title={title} breadcrumbs={breadcrumbs} />
      <Container width="medium" pt={2} pb={2}>
        <FindARetailer pageLeadText={pageLeadText} />
        <div>
          <div class="storerocket-store-locator"></div>
          <Script
            src="//cdn.storerocket.io/widget.js"
            onLoad={() =>
              isBrowser &&
              window.StoreRocket.init({
                selector: '.storerocket-store-locator',
                account: 'wzpAvvj4dD',
              })
            }
            onError={() => console.log('sadness')}
          />
        </div>
        <FindARetailer contactUs={contactUs} contactLink={contactPage} />
      </Container>
    </PageWrapper>
  );
};

export default DealerPages;

export const query = graphql`
  query DealerPageQuery($locale: String!) {
    datoCmsDealerPage(locale: { eq: $locale }) {
      locale
      title
      pageLeadText
      contactUs
      useStoreLocator
      metaTags {
        title
        description
        image {
          url
        }
      }
      id: originalId
    }
    homeCrumb: datoCmsHomepage(locale: { eq: $locale }) {
      locale
      title
      id: originalId
    }
    contactPage: datoCmsContactPage(locale: { eq: $locale }) {
      id: originalId
    }
  }
`;
