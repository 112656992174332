import React from 'react';
import { Navigator } from '../LanguageHelpers/Navigator';
import style from './find-a-retailer.mod.scss';

const FindARetailer = ({ pageLeadText, contactUs, contactLink }) => {
  return (
    <>
      <div className={style.retailer}>
        {pageLeadText && (
          <div className={style.retailer__heading}>{pageLeadText}</div>
        )}
        {contactUs && (
          <div className={style.retailer__contact}>
            {contactUs}
            <Navigator recordId={contactLink.id}>Contact us</Navigator>
          </div>
        )}
      </div>
    </>
  );
};

export default FindARetailer;
